"use client";

/**
 * Third-party libraries.
 */
import { ExclamationCircleFilled, ImportOutlined, UserOutlined } from "@ant-design/icons";
import { Contact } from "@generated/type-graphql";
import { Avatar, Button, Tooltip } from "antd";
import { FC, useMemo } from "react";

/**
 * Project components.
 */
import { CopyButton } from "@/components/client/button";
import { Card } from "@/components/client/card";
import { ContactSearchField, useContactsQuery } from "@/components/client/graphql";

/** The only data required from the Contact model for the ContactProfile component. */
type ContactProfileData = Pick<Contact, "id" | "displayName" | "email" | "phoneNumbers" | "photoUrl" | "issues">;

/** Types for the ContactProfile component. */
type ContactProfileProps = {
  /** The id, displayName, email, phoneNumbers, and photoUrl of the contact. */
  data?: ContactProfileData;
  /** The flag to determine if the component should be visible. */
  visible: boolean;
  /** The class name for the container of the component. */
  className?: string;
};

/** Component for displaying the name, email, phoneNumbers, and photoUrl of the contact. */
const ContactProfile: FC<ContactProfileProps> = props => {
  const {
    data: contact,
    visible,
    className
  } = props;

  // ===========================================================================
  // States
  // ===========================================================================

  const {
    data
  } = useContactsQuery({
    variables: {
      filter: {
        searchText: contact?.phoneNumbers.find(phoneNumber => phoneNumber.primary)?.rawForm || contact?.phoneNumbers[0]?.rawForm || "",
        includeFields: [ContactSearchField.PhoneNumber]
      }
    }
  });

  // ===========================================================================
  // States
  // ===========================================================================
  /** Primary phone number. */
  const primaryPhoneNumber = useMemo(() => {
    return contact?.phoneNumbers.find(phoneNumber => phoneNumber.primary);
  }, [contact]);

  /** List of all secondary phone numbers. */
  const secondaryNumbers = useMemo(() => {
    return contact?.phoneNumbers.filter(phoneNumber => !phoneNumber.primary) || [];
  }, [contact]);
  if (!visible) {
    return null;
  }

  // ===========================================================================
  // Render
  // ===========================================================================

  return <Card header={<div className="flex items-center gap-2">
          {contact?.photoUrl ?
    // eslint-disable-next-line @next/next/no-img-element
    <img src={contact.photoUrl} alt="" className="h-8 w-8 rounded-full" /> : <Avatar icon={<UserOutlined />} />}
          <h2 className="m-0">{contact?.displayName}</h2>
          {contact?.issues.length! > 0 && <Tooltip placement="bottom" title={<div>
                  <div className="font-bold">Issues:</div>
                  {contact?.issues.map(issue => <div key={issue.name + issue.message} className="py-1">
                      <div className="text-sm font-black">{issue.name}</div>
                      <div className="text-xs">{issue.message}</div>
                    </div>)}
                </div>}>
              <ExclamationCircleFilled className="text-lg !text-semantic-red" />
            </Tooltip>}
        </div>} data-sentry-element="Card" data-sentry-component="ContactProfile" data-sentry-source-file="contact-profile.tsx">
      {contact?.email && <div className="mt-2 flex items-center gap-2">
          <span className="text-sm">Email: </span>
          <span className="text-sm text-tpl-blue">{contact?.email}</span>
          <CopyButton value={contact?.email || ""} />
        </div>}

      {primaryPhoneNumber && <div className="mt-2 flex items-center gap-2">
          <span className="text-sm">Main: </span>
          <span className="text-sm text-tpl-blue">
            {primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm}
          </span>
          {primaryPhoneNumber.label && <span className="text-sm text-tpl-navy-light">
              ({primaryPhoneNumber.label})
            </span>}
          <CopyButton value={primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm} />
        </div>}

      {secondaryNumbers.length > 0 && <div className="mt-2 flex gap-2">
          <span className="text-sm">Secondary: </span>
          <div className="flex flex-col gap-2">
            {secondaryNumbers?.map(phoneNumber => <div key={phoneNumber.rawForm} className="flex items-center gap-2">
                <span className="text-sm text-tpl-blue">
                  {phoneNumber.canonicalForm || phoneNumber.rawForm}
                </span>
                {phoneNumber?.label && <span className="text-sm text-tpl-navy-light">
                    ({phoneNumber?.label})
                  </span>}
                <CopyButton value={phoneNumber.canonicalForm || phoneNumber?.rawForm} />
              </div>)}
          </div>
        </div>}
      {/* Uncomment to add "View Profile on Archus" */}
      {data?.contacts.items[0]?.profileUrl && <div className="mt-0 flex w-full items-center gap-1 border-t border-[#E8EBF1] pt-2">
          <Button className="!px-0" icon={<ImportOutlined rotate={90} />} iconPosition="end" onClick={() => {
        window.open(data?.contacts.items[0].profileUrl || "", "_blank");
      }} type="link">
            View Profile on Archus
          </Button>
        </div>}
    </Card>;
};
export default ContactProfile;