"use client";

import { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
type CardTitle = {
  /**
   * The title to show on the header of the card.
   */
  title: string;
};
type CardHeader = {
  /**
   * Custom header to show on the card.
   */
  header?: ReactNode;
};

/**
 * Properties for the Card component.
 */
type CardProps = (CardTitle | CardHeader) & {
  /**
   * Custom body style.
   */
  bodyAttributes?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
};

/**
 * Default card title component.
 */
function CardTitle(props: {
  title: string;
}) {
  return <div className="text-sm font-semibold text-tpl-navy" data-sentry-component="CardTitle" data-sentry-source-file="card.tsx">{props.title}</div>;
}

/**
 * The card body component.
 */
function CardBody(props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const {
    children,
    ...rest
  } = props;
  return <div className="flex flex-col items-start justify-start gap-4 self-stretch p-4" {...rest} data-sentry-component="CardBody" data-sentry-source-file="card.tsx">
      {children}
    </div>;
}

/**
 * Displays a card with a title and a body.
 */
export function Card(props: PropsWithChildren<CardProps>) {
  return <div className="flex w-full animate-slide-left flex-col items-start justify-start self-stretch rounded-md border bg-white" data-sentry-component="Card" data-sentry-source-file="card.tsx">
      <div className="inline-flex items-center justify-between self-stretch border-b p-4" style={{
      borderBottom: "1px solid rgba(0,0,0,0.1)"
    }}>
        {"title" in props && <CardTitle title={props.title} />}
        {"header" in props && props.header}
      </div>
      <CardBody {...props.bodyAttributes} data-sentry-element="CardBody" data-sentry-source-file="card.tsx">{props.children}</CardBody>
    </div>;
}
Card.Title = CardTitle;
Card.Body = CardBody;